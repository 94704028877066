import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import TextBlocks from "../components/TextBlocks";
import Layout from "../components/Layout";
import GraphQLErrorList from "../components/GraphQLErrorList";
import { imageUrlFor } from "../utils/image-url";
import {  buildImageObj } from '../utils/helpers';
const Root = styled.div``;

const WEBROOT = process.env.GATSBY_APP_UI_URL;

export const query = graphql`
  query blogPostQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      title
      excerpt
      _rawMainImage
      _rawSlug
      _rawBody(resolveReferences: { maxDepth: 10 })
      publishedAt(formatString: "LL")
    }
    settings: sanitySiteSettings {
      title
      id
      facebook
      instagram
      twitter
      _rawOpenGraph(resolveReferences: { maxDepth: 10 })
      _rawLogo(resolveReferences: { maxDepth: 10 })
    }
    footer: sanitySiteFooter {
      _rawContent(resolveReferences: { maxDepth: 10 })
      _rawLinks(resolveReferences: { maxDepth: 10 })
      _rawSlug(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Blogs = ({ data, errors }) => {
  if (errors) {
    return <GraphQLErrorList errors={errors} />;
  }

  const { post, settings, footer } = data;

  return (
    <Layout
      footer={footer}
      settings={settings}
      title={post.title}
      description={post.excerpt}
      image={post._rawMainImage}
    >
      <Root>
        <Helmet>
          <meta
            property="og:url"
            content={`${WEBROOT}/blog/${post._rawSlug.current}`}
          />
          <meta property="og:type" content="article" />
        </Helmet>
        {!post._rawMainImage ? (
          <div className="section-spacer">
            <div className="row">
              <div className="col-xs-18 col-md-16 col-md-offset-1 align-justify">
                <h1 className="xl text-blue">{post.title}</h1>
              </div>
            </div>
          </div>
        ) : (
          <div className="row no-overflow">
            <div className="col-xs-18 col-sm-10 col-md-9 col-md-offset-1 col-lg-9 align-justify">
              <h1 className="xl text-blue">{post.title}</h1>
            </div>
            <div className="col-xs-18 col-sm-8">
              <div className="shape-media">
                <div className="shape shape-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 600 600"
                  >
                    <defs>
                      <pattern
                        id="img1"
                        height="100%"
                        width="100%"
                        patternContentUnits="objectBoundingBox"
                      >
                        <image
                          xlinkHref={imageUrlFor(buildImageObj(post._rawMainImage))
                            .width(800)
                            .url()}
                          alt={post.title}
                          x="0"
                          y="0"
                          width="1"
                          height="1"
                          preserveAspectRatio="xMinYMin slice"
                        />
                      </pattern>
                    </defs>
                    <path
                      fill="url(#img1)"
                      fillRule="evenodd"
                      d="M433.445131 3.030174C227.394072-23.418171 214.234976 147.420491 137.592715 213.386778 60.950454 279.353064-34.894806 322.332135 12.731924 415.74737c47.62673 93.415234 140.852813 140.852813 300.157961 141.467891 97.155166.375117 191.346332-19.608569 271.951579-70.558054V48.962635C539.413719 26.08014 487.129055 9.920946 433.445131 3.030174z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="blog-post">
          <div className="row">
            <div className="col-xs-18 col-md-12 col-md-offset-3">
              <article>
                <TextBlocks data={{ blocks: post._rawBody }} />
                <aside>Posted on: {post.publishedAt}</aside>
              </article>
            </div>
          </div>
        </div>
      </Root>
    </Layout>
  );
};

export default Blogs;
